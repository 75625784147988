@keyframes pulse
{
 50%   {box-shadow: var(--shadow-menu-contact);}
}

/* ----------------- Side Menu ---------------*/
#menu_container
{
 width: 100%;
}

#div_lang_side
{
 width: 100%;
 text-align: right;
 font-size: 0;
 padding-top: 5px;
}

#side_menu
{
 display: none;
 position: fixed;
 right: 0;
 width: 0;
 height: 100vh;
 overflow: hidden;
 background-color: black;
 transition: width 0.7s ease-out;
 color: white;
 z-index: 5;
}

#menu_ul_side
{
 width: 100%;
 height: 100%;
 list-style-type: none;
 padding: 25px 30px 0px 30px;
 margin: 0;
 font-size: var(--fontSize);
}

.menu_a_side
{
 text-decoration: none;
 color: white;
}

#menu_ul_side
{
 padding: 30px 20px 0px 20px !important;
}

.menu_link_side
{
 cursor: pointer;
 padding: 10px 10px 10px 10px;
 width: 260px;
}

#close_side_menu
{
 position: absolute;
 top: 0;
 left: 0;
 font-size: 36px;
 padding: 0px 20px 0px 20px;
 cursor: pointer;
}

.menu_link_contact_side
{
 animation-name: pulse;
 animation-duration: 5s;
 animation-iteration-count: infinite;
 animation-direction: normal;
 border-radius: 15px;
 padding: 0px 10px 0px 10px;
}

#div_flags
{
 position: absolute;
 right: 10px;
 top: 5px;
}

#side_lang_container
{
 display: none;
 position: absolute;
 background-color: var(--bg_color);
 min-width: 110px;
 z-index: 1;
}

#side_lang_container a
{
 padding: 3px 5px;
 text-decoration: none;
 display: block;
}

.show_side_lang
{
 display: block !important;
}

#select_lang
{
 background-color: var(--bg_color);
 border: none;
 color: white;
}

.lang_flag
{
 padding: 0px 4px 0px 4px;
}
/* ----------------- End Side Menu ---------------*/
.menu_a
{

}

#menu_btn
{
 display: none;
 float: right;
}

#ul_menu
{
 list-style-type: none;
 margin: 0;
 padding: 0;
 height: 100%;
 text-align: center;
}

.li_menu
{
 display: inline;
 padding: 20px;
 border-radius: 15px;
 font-family: var(--menuFontFamily);
 font-style: normal;
 font-size: var(--fontSize);
}

.li_menu:hover
{
 box-shadow: var(--shadow-menu);
}

.li_contact
{
 animation-name: pulse;
 animation-duration: 5s;
 animation-iteration-count: infinite;
 animation-direction: normal;
}

.li_contact:hover
{
 animation-play-state: paused;
}
@media only screen and (max-width: 1920px) /* wide monitor */
{

}
@media only screen and (max-width: 768px) /* phone 768px */
{
 #menu_btn
 {
  display: block;
  top: 0;
 }
 #ul_menu
 {
  display: none;
 }
 #side_menu
 {
 display: block;
 }
 .lang_flag
 {
   padding: 0px 4px 0px 4px;
 }
}
@media only screen and (max-width: 480px) /* phone 480px */
{
 
}