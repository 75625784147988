#offers_section_mini
{
 width: 100%;
}

#offers_container_mini
{
 display: flex;
 flex-direction: row;
 flex-wrap: wrap;
 justify-content: center;
}

.offer_main_mini
{
 width: 400px;
 border-radius: 15px;
 margin: 0px 15px;
}

.offer_main_mini:hover
{
 box-shadow: var(--shadow-menu);
}

.offer_title_mini
{
 display: block;
 font-size: 1em;
 font-weight: bold;
 padding: 10px;
 margin: 5px 0px;
 background-color: var(--button-color);
 border-radius: 15px 15px 0px 0px;
}

.offer_img_mini
{
 max-width: 100%;
 max-height: 300px;
 border-radius: 0px 0px 15px 15px;
 vertical-align: bottom;
}

@media only screen and (max-width: 1440px) /* wide monitor */
{
 .offer_main_mini
  {
  width: 300px;
  border-radius: 15px;
  margin: 0px 15px;
  }
}

@media only screen and (max-width: 768px) /* phone 768px */
{
#offers_container_mini
{
 display: flex;
 flex-direction: column;
 justify-content: center;
}
.offer_main_mini
{
 width: calc(100% - 30px);
 border-radius: 15px;
}
}