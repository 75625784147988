:root
{
 --bg_color: #000000;
 --maintop_height: 50px;
 --shadow-color: 216deg 84% 59%;
 --shadow-menu: 0px 0px 20px 10px hsl(var(--shadow-color) / 0.90);
 --shadow-price: 0px 0px 5px 5px hsl(var(--shadow-color) / 0.70);
 --shadow-menu-contact: 0px 0px 7px 4px hsl(var(--shadow-color) / 0.90);
 --shadow-elevation-high:
    0.1px -5.2px 9.3px hsl(var(--shadow-color) / 0.40),
    0.5px 1.5px 1.9px -0.2px hsl(var(--shadow-color) / 0.25),
    0.9px 2.7px 3.5px -0.5px hsl(var(--shadow-color) / 0.25),
    1.4px 4.1px 5.3px -0.7px hsl(var(--shadow-color) / 0.27),
    2px 6px 7.7px -0.9px hsl(var(--shadow-color) / 0.29),
    2.9px 8.7px 11.1px -1.2px hsl(var(--shadow-color) / 0.31),
    4.2px 12.4px 15.9px -1.4px hsl(var(--shadow-color) / 0.32),
    5.9px 17.5px 22.4px -1.7px hsl(var(--shadow-color) / 0.34),
    8.2px 24.1px 30.9px -1.9px hsl(var(--shadow-color) / 0.35);
 --fontFamily: Verdana, Georgia;
 --fontSize: 24px;
 --fontSizeM: 20px;
 --fontSizeBottom: 16px;
 --fontSizeBottomM: 18px;
 --menuFontFamily: Poppins;
 --menufontSize: 30px;
 --fontColor: white;
 --button-color: #ff7701;
 --button-font-color: white;
}

html, body
{
 height: 100%;
}

body 
{
 margin: 0;
 background-color: var(--bg_color);
 color: var(--fontColor);
 font-family: var(--fontFamily);
 font-style: normal;
 font-size: var(--fontSize);
}

*
{
 box-sizing: border-box;
 caret-color: rgba(0,0,0,0);
}

.img_container_dim
{
 max-width: 100%;
 max-height: 100%;
}

a
{
 text-decoration: none;
 color: white;
}

#root
{
 height: 100%; 
}

#div_spinner
{
 position: absolute;
 width: 120px;
 height: 170px;
 z-index: 12;
 margin: auto;
 left: 0;
 right: 0;
 top: 150px;
}

@keyframes rotation
{
 from {
	 transform: rotate(0deg);
 }
 to {
	 transform: rotate(359deg);
 }
}

#img_spinner
{
 animation: rotation 5s infinite linear;
}

@media only screen and (max-width: 1440px) /* wide monitor */
{
 body 
  {
   font-size: var(--fontSizeM);
  }
}