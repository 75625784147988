#prices_tb
{
 width: 70%;
 margin: auto;
 border-collapse: separate;
 border-spacing: 0;
}

.price_th
{
 width: 50%;
}

.price_tr
{
 height: 40px;
}

.price_tr:hover td
{
 background-color: #D6EEEE;
 color: black;
}

.price_per_td
{
 border-radius: 20px 0px 0px 20px;
}

.price_pr_td
{
 border-radius: 0px 20px 20px 0px;
}
@media only screen and (max-width: 768px) /* phone 768px */
{
 #prices_tb
 {
  width: 100%;
 }
 .price_th
 {
  width: 60%;
 }
}