::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: black;
}
::-webkit-scrollbar-thumb {
  background-color: #3F85EE;
  border: transparent;
}

a:link, a:active
{
 color:var(--f_color_btn);
 text-decoration: none;
}

a:visited
{
 color:var(--f_color_btn);
}

.App
{
 margin: auto;
 height: 100%;
 width: 90%;
}

.window_base
{
 z-index: 9;
 opacity: 0.5;
 background-color: var(--bg_color);
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 transition: opacity 0.6s;
}

#window_container
{
 z-index: 10;
 opacity: 1;
 transition: opacity 0.6s;
 background-color: var(--bg_color);
 border-radius: 10px;
 border-style: solid;
 border-width: 1px;
 border-color: #2e8ae1;
 box-shadow: var(--shadow-elevation-high);
 background-image: url("https://www.camper.professionalservices.ro/images/bg-2.jpg");
 background-size: cover;
 background-repeat: no-repeat;
 position: fixed;
 top: 100px;
 left: 50%;
 margin-left: -375px;
 width: 750px;
 height: calc(100% - 200px);
 max-height: 700px;
 display: flex;
 flex-direction: column;
}

#window_title
{
 margin: 5px;
 line-height: 40px;
 border-style: solid;
 border-width: 0px 0px 1px 0px;
 border-color: #2E8AE1;
 text-align: center;
}

.window_main
{
 margin: 5px;
 padding: 0 20px;
 flex-grow: 1;
 overflow-Y: auto;
 scrollbar-color: #3F85EE black;
 scrollbar-width: thin;
}

.window_ctrl
{
 padding: 5px;
 text-align: right;
 border-style: solid;
 border-color: #2E8AE1;
 border-width: 1px 0px 0px 0px;
 margin: 5px;
}

.window_ctrl_btn
{
 min-width: 100px;
 height: 25px;
 color: white;
 background-color: #000000;
 border-style: solid;
 border-width: 2px;
 border-color: #2E8AE1;
 border-radius: 10px;
 margin: 0px 5px;
 cursor: pointer;
}

#top
{
 position: relative;
 width: 100%;
 height: 150px;
 display: flex;
 flex-direction: row;
}

#logo_container
{
 width: 20%;
 height: 100%;
 padding: 10px;
 display: flex;
 align-items: center;
}

#logo_img
{
 border-radius: 20px;
}

#top_menu
{
 width: 80%;
 height: 100%;
 position: relative;
 display: flex;
 align-items: center;
}

#top_lang
{
 position: absolute;
 right: 0;
 top: 0;
 display: flex;
 flex-direction: row;
}

.lang_div
{
 width: 25px;
 margin: 5px;
 height: 18px;
 display: flex;
 align-items: center;
}

#div_main
{
 height: 100%;
}

#middle_container
{
 width: 100%;
 height: calc(100% - 180px);
 border-radius: 20px;
 box-shadow: var(--shadow-elevation-high);
 margin-bottom: 10px;
 background-image: url("https://www.camper.professionalservices.ro/images/bg-2.jpg");
 background-size: cover;
 background-repeat: no-repeat;
 padding-top: 20px;
 padding-bottom: 20px;
}

#middle
{
 width: 100%;
 height: 100%;
 display: flex;
 flex-direction: column;
 scrollbar-color: #3F85EE black;
 scrollbar-width: thin;
 overflow: auto;
}

#prez_images
{
 position: relative;
 height: 360px;
}

.row_left_div
{
 width: 40%;
}

.row_right_div
{
 width: 60%;
}

.img_pres
{
 opacity: 0;
 margin: auto;
 transition: opacity 2s;
}

.img_pres_active
{
 opacity: 1;
 transition: opacity 2s;
}

.img_nr
{
position: absolute;
top: 0;
left: 0;
right: 0;
}

#span_group
{
 order: 1;
}

#div_1_txt
{
 display: flex;
 flex-direction: column;
}

#row_2_visit_div
{
 order: 3;
 width: 80%;
 max-width: 500px;
 margin: auto;
 margin-top: 10px;
}

.white_text_div, .black_text_div
{
 text-align: center;
 padding: 20px;
 color: white;
}

.black_text_div
{
 color: black;
}

.rows-h, .rows-v
{
 display: flex;
 flex-direction: row;
 margin: 80px 10px;
 position: relative;
}

.rows-v
{
 flex-direction: column;
}

.row_center_div
{
 text-align: center;
 white-space: pre-line;
}

#tarife_int, #offers_int
{
 width: 100%
}

#rezerva-acum
{
 padding-left: 5%;
}

#row_1_res_div
{
 order: 2;
 width: 100%;
 display: flex;
 flex-direction: row;
 max-width: 670px;
 margin: 0 auto;
}

#row_1_res_cal
{
 width: 70%;
 text-align: right;
 position: relative;
}

#row_1_res_btn
{
 width: 30%;
 text-align: left;
}

.res_btn
{
 width: 100%;
 padding: 6px 10px;
 border-style: solid;
 border-width: 2px;
 border-color: orange;
 border-radius: 10px;
 background-color: var(--button-color);
 font-size: var(--fontSize);
 color: var(--button-font-color);
 
}

.res_btn:hover
{
 background-color: lightblue;
}

#bottom
{
 width: 100%;
 height: 20px;
 text-align: center;
 font-size: var(--fontSizeBottom);
}

@media only screen and (max-width: 1280px) /* wide monitor */
{
 #top
 {
  height: 100px;
 }
 #middle_container
 {
  height: calc(100% - 150px);
 }
 .rows-h
 {
  margin: 60px 10px;
 }
 #row_1_res_div
 {
  max-width: 650px;
 }
}

@media only screen and (max-width: 768px) /* phone 768px */
{
 #main_container
 {
  width: 100%;
 }
 #top
 {
  height: 60px
 }
 #top_lang
 {
  display: none;
 }
 #logo_container
 {
  width: 40%;
  padding: 0 10px 10px 10px;
 }
 #middle_container
 {
  height: calc(100% - 60px);
  padding-top: 5px;
  padding-bottom: 5px;
 }
 #bottom
 {
  display: none;
 }
 .white_text_div
 {
  padding: 0px 5px 10px 5px;
 }
 .rows-h
 {
  flex-direction: column;
  margin: 30px 5px;
 }
 #Home
 {
  margin-top: 10px;
 }
 #prez_images
 {
  height: 290px;
  margin-bottom: 5px;
 }
 #span_group
 {
  order: 2;
 }
 #row_1_res_div
 {
  order: 1;
  flex-direction: column;
 }
 #row_1_res_cal
 {
  width: 100%;
  text-align: center;
 }
 #row_1_res_btn
 {
  width: 100%;
  text-align: center;
 }
 .row_left_div
 {
  width: 100%;
 }
 #row_div_1
 {
  flex-direction: column;
 }
 .row_right_div
 {
  width: 100%;
 }
 .f_cont
 {
  width: 100%;
 }
}

@media only screen and (max-width: 480px) /* phone 480px */
{
 #home
 {
  margin-top: 10px;
 }
 #prez_images
 {
  height: 305px;
  margin-bottom: 5px;
 }
 #window_container
 {
  left: 0;
  margin-left: 0;
  width: 100%;
  height: calc(100% - 100px);
  max-height: 500px;
 }
 #window_main
 {
  padding: 0;
 }
 #form_visit_container
 {
  width: 95%;
 }
}