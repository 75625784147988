#al_window_base
{
 z-index: 11;
}

#al_window_container
{
 z-index: 12;
 opacity: 1;
 transition: opacity 0.6s;
 background-color: var(--bg_color);
 border-radius: 10px;
 border-style: solid;
 border-width: 1px;
 border-color: #2e8ae1;
 background-image: url("https://www.camper.professionalservices.ro/images/bg-2.jpg");
 background-size: cover;
 background-repeat: no-repeat;
 position: fixed;
 top: 100px;
 left: 50%;
 margin-left: -375px;
 width: 750px;
 height: 200px;
 display: flex;
 flex-direction: column;
}

#al_window_main
{
 padding-top: 20px;
 text-align: center;
}