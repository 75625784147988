.f_label
{
 color: white;
 line-height: 2;
}

.f_label_ch
{
 padding: 0 10px 10px 10px;
 display: inline-block;
}

.f_input, .f_opt
{
 width: 100%;
 padding: 6px 10px;
 border-style: solid;
 border-width: 2px;
 border-color: orange;
 border-radius: 10px;
 caret-color: black;
 font-size: var(--fontSize);
}

.f_opt
{
 text-align: center;
 background-color: white;
}

.f_text
{
 width: 100%;
 height: 100px;
 border-style: solid;
 border-width: 2px;
 border-color: orange;
 border-radius: 10px;
 caret-color: black;
}

.f_btn
{
 width: 100px;
 border-style: solid;
 border-width: 2px;
 border-color: orange;
 border-radius: 10px;
}

.f_ch
{
 margin-right: 20px;
}

.f_section
{
 margin-top: 10px;
}

.edit_cont_f_btn
{
 text-align: center;
}

.div_ch_acord
{
 display: flex;
 flex-direction: row;
}

.div_acord_min
{
 flex-grow: 1;
 height: calc(1em + 5px);
 overflow: hidden;
 cursor: pointer;
}

.div_acord_extend
{
 height: 6em !important;
 overflow-y: scroll !important;
 scrollbar-color: #3F85EE black;
 scrollbar-width: thin;
}

.acord_dots_hide
{
 display: none;
}

.acord_txt_end_hide
{
 visibility: hidden;
}